import onDOMContentLoaded from "@public-modal/shared/scripts/on-dom-content-loaded"
import { getHandlers } from "@public-modal/shared/scripts/handler"
import { CUSTOM_MODAL_KEY } from "@public-modal/shared/keys/custom-modal-key"
import { createContainer, getContainer } from "@public-modal/shared/scripts/container"

onDOMContentLoaded(() => {
    const handlers = getHandlers(CUSTOM_MODAL_KEY)

    const modals = []

    handlers.forEach(handler => {
        const key = handler.dataset.modalHandler
        if (!modals.includes(key)) {
            modals.push(key)
            const container = createContainer(key, true)

            const template = document.querySelector(`#${key}`)

            if (template) {
                const clone = template.content.cloneNode(true)
                container.querySelector(".public-modal-container").appendChild(clone)
            }
        }

        handler.addEventListener("click", () => {
            const container = getContainer(key)
            container.firstChild.style.display = "block"

            document.addEventListener("click", ev => {
                if (
                    (!ev.target.closest(`[data-${key}-container]`) &&
                        !ev.target.closest(`[data-modal-handler='${key}']`)) ||
                    (ev.target.classList && ev.target.classList.contains("public-modal-overlay")) ||
                    (ev.target.classList && ev.target.classList.contains("close-icon"))
                ) {
                    container.firstChild.style.display = "none"
                }
            })
        })
    })
})
